<template>
  <div id="notFound">
    <div class="not-found-img">
<!--      <img src="../assets/404.png" height="100%" width="100%"/>-->
<!--      <el-image
              style="width: 100%; height: 100%"
              :src="require('@/assets/png/404.png')"
              fit="contain"></el-image>-->
    </div>

  </div>
</template>

<script>
    export default {
        name: "notFound",
      created() {
          window.location.assign("https://m.pp.cn/detail.html?appid=8287197&ch_src=pp_dev&ch=default")
      }
    }
</script>

<style scoped>
  #notFound{
    height: 100%;
    /* 垂直居中 */
    align-items: center;
  }
  .not-found-img{

  }

</style>